import React, { useState, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "../components/row"
import Spacer from "../components/spacer"
import Arrow from "../components/arrow"
import SectionTitle from "../components/sectionTitle"
import Button from "../components/button"

import l from "../components/layout.module.scss" // layout styles
import c from "./contact.module.scss" // contact styles
import Image from "../components/image"

const ContactPage = () => {
  const [formEntries, saveFormEntries] = useState({})
  const [formSent, setFormSent] = useState(false)
  const [formIsProcessing, setFormIsProcessing] = useState(false)

  const formRef = useRef()

  const api = "https://limach.nl/wp/mailer/index.php"

  const {
    hero_title,
    hero_subtitle,
    form_title,
    contact_opties,
    form_image,
  } = useStaticQuery(graphql`
    query {
      wordpressPage(slug: { eq: "contact" }) {
        acf {
          contact {
            hero_title
            hero_subtitle
            form_title
            contact_opties {
              content
              title
              url
            }
            form_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `).wordpressPage.acf.contact

  const handleFormEntry = e => {
    saveFormEntries({
      ...formEntries,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = e => {
    e.preventDefault()

    setFormIsProcessing(true)
    fetch(api, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: JSON.stringify(formEntries),
    })
      .then(response => response.json())
      .then(data => {
        //Handle your data
        if (data === "success") {
          formRef.current.reset()
          setFormIsProcessing(false)
          setFormSent(true)

          // remove success message after 5 seconds
          let timeout = setTimeout(() => {
            setFormSent(false)
            clearTimeout(timeout)
          }, 5000)
        }
      })
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Row backgroundColor="#f2f2f2">
        <div className={[l.col1, l.title_section].join(" ")}>
          <h1 className={l.title}>{hero_title}</h1>
          <h3 className={l.sub_title}>{hero_subtitle}</h3>
        </div>
        <div
          style={{ marginBottom: "2rem" }}
          className={[l.col1, l.section_grid_3].join(" ")}
        >
          {contact_opties.map(option => (
            <ul className={c.options} key={option.title}>
              <li>
                <strong>{option.title}</strong>
              </li>
              <li>
                <a href={option.url}>{option.content}</a>
              </li>
            </ul>
          ))}
        </div>
        <Spacer>
          <Arrow rotation="90deg" fontWeight="bold" fontSize="18px">
            Contactformulier
          </Arrow>
        </Spacer>
      </Row>
      <Row>
        <SectionTitle title={form_title} />
        <div className={l.col1}>
          <div className={l.section_grid_05}>
            <div className={c.form}>
              <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                <div className={l.section_grid_05}>
                  <div className={c.form_group}>
                    <input
                      type="text"
                      name="bedrijfsnaam"
                      id="bedrijfsnaam"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="bedrijfsnaam">Bedrijfsnaam</label>
                  </div>
                  <div className={c.form_group}>
                    <input
                      type="text"
                      name="naam"
                      id="naam"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="naam">Naam</label>
                  </div>
                  <div className={c.form_group}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className={c.form_group}>
                    <input
                      type="phone"
                      name="telefoon"
                      id="telefoon"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="telefoon">Telefoonnummer</label>
                  </div>
                </div>
                <div className={l.col1}>
                  <div className={c.form_group}>
                    <input
                      type="text"
                      name="onderwerp"
                      id="onderwerp"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="onderwerp">Onderwerp</label>
                  </div>
                  <div className={c.form_group}>
                    <textarea
                      rows="3"
                      name="bericht"
                      id="bericht"
                      onChange={e => handleFormEntry(e)}
                      required
                    />
                    <label htmlFor="bericht">Bericht</label>
                  </div>
                </div>
                <div className={[c.message, formSent && c.success].join(" ")}>
                  Uw aanvraag werd successvol verstuurd!
                </div>
                <Button color="black">
                  {formIsProcessing ? "Verwerken..." : "Verstuur"}
                </Button>
                <div className={c.consent}>
                  Met het versturen van dit formulier gaat u akkoord met onze{" "}
                  <Link to="/privacyverklaring">privacyverklaring</Link>
                </div>
              </form>
            </div>
            <div className={c.image}>
              <Image data={form_image} />
            </div>
          </div>
        </div>
      </Row>
    </Layout>
  )
}

export default ContactPage
